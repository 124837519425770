/*
 * @Author: 哈库呐玛塔塔
 * @Date: 2022-11-18 15:03:08
 * @Descripttion: 
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-18 21:54:55
 */
import React, { useEffect, useState } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import style from './index.module.less'
import {
    Cards,
    HeaderSwiper
} from '@/components/Arlab/index'
import { useIntl } from "react-intl"
import AndroidIcon from '@/images/SVG/arlabAndroid.svg'
import MacIcon from '@/images/SVG/arlabMac.svg'
import classNames from 'classnames'
import { cinemaAppPoster } from '@/images/index'
import { getAppShow } from '@/services/request'
import { useGTMInit} from '@/hooks/useGTM.js'

const Index = (props) => {
    const [dataAndroid, setAndroidData] = useState([])
    const [dataHeader, setDataHeader] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const intl = useIntl()
    const tabList = [
        {
            type: 'Android',
            icon: AndroidIcon
        },
        {
            type: "macOS",
            icon: MacIcon
        }
    ]
    const macData = [
        {
            id: 1,
            poster: cinemaAppPoster,
            name: 'Spatial Cinema',
            tags: [],
            supportDevice: ["Air"],
            link: '/arlab/detail/for-mac?appname=SpatialCinema'
        }
    ]
    const getdata = () => {
        getAppShow({ tag: 'Recommend' })
            .then(d => {
                setDataHeader(d?.data?.data)
            })
        getAppShow()
            .then(d => {
                setAndroidData(d?.data?.data)
            })
            .catch(err => {
                // setShouldRender(true)
            })

    }
    useEffect(() => {
        getdata()
        useGTMInit('arlab','arlab')

    }, [])

    return (
        <Layout menuBlack={false} >
            <SEO page="AR_Lab" />
            <div className={style.arlabBg}>
                <HeaderSwiper data={dataHeader} />
                <div className={style.arlabTab}>
                    {tabList.map((item, index) => (
                        <div className={classNames(style.arlabTabItem, index === tabIndex ? style.arlabTabItem_active : "")} key={`arlabTab${item.type}`} onClick={() => setTabIndex(index)}>
                            {index == 0 ? <AndroidIcon /> : <MacIcon />}
                            {item.type}
                        </div>
                    ))}
                </div>
                <div className={style.desc} style={{ marginBottom: tabIndex == 0 ? '36px' : "60px" }}>
                    <div className={style.descText}>
                        {tabIndex == 0 ? intl.formatMessage({ id: "arlab_dec", defaultMessage: " Since the introduction of our SDK, many developers have expressed their creativity through building AR apps. Today, there are apps designed to improve surgical precision for medical staff, amplify in-theatre visual effects, exhibit digital arts and enrich tourist experiences. In addition to business applications, there are also some really interesting consumer applications. We have selected a few for your pleasure." }) : intl.formatMessage({ id: "arlab_dec_mac", defaultMessage: " Discover the future of AR on macOS! Immerse yourself in an interactive world where virtual elements seamlessly merge with reality on your Mac. Explore captivating AR experiences tailored for macOS, unlocking limitless possibilities. Step into the extraordinary realm of AR on macOS and redefine your interaction with technology. Download our cutting-edge AR app for macOS today!" })}


                    </div>
                </div>

                {tabIndex === 0 ? <>
                    <div className={style.descTip} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `arlab_dec_tip`, defaultMessage: "Running AR apps requires <a href=\"/app/#download\">Nebula for Android</a>. You may find <a href=\"/support/nebula/#compatibility\">compatibility information here</a>. " })}` }}></div>
                    <Cards data={dataAndroid} />
                </> :
                    <>
                        <Cards data={macData} type='mac' />
                    </>}

            </div>
        </Layout>
    )
}
export default Index